<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col md="6" cols="12" data-cy="building-info">
          <building-heading v-if="building" v-on:load="loadBuilding"></building-heading>
          <v-row v-if="$route.params.building && !isClearanceCert">
            <v-col class="table-max">
              <h2 class="mb-3 building-information-step">Building information</h2>
              <v-simple-table class="elevation-1">
                <tbody>
                  <tr data-cy="floor-row">
                    <td>
                      <p class="body-1 ma-0 pa-0">Floors</p>
                    </td>
                    <td>
                      <p class="body-1 ma-0 pa-0 text-center" data-cy="count-floor">
                        {{ building.floors_count }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p class="body-1 ma-0 pa-0">Rooms</p>
                    </td>
                    <td>
                      <p class="body-1 ma-0 pa-0 text-center" data-cy="count-room">
                        {{ building.rooms_count }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p class="body-1 ma-0 pa-0">Items</p>
                    </td>
                    <td>
                      <v-row>
                        <v-col class="pa-0 ma-0 ml-8">
                          <p class="body-1 my-0 pa-0 text-center" data-cy="count-item">
                            <building-items v-if="!notBuilding"></building-items
                            ><span v-else>{{ building.items_count }}</span>
                          </p>
                        </v-col>
                        <v-btn icon data-cy="sort-order">
                          <v-icon
                            data-cy="sort-down"
                            class="float-right"
                            v-if="!showSamples"
                            @click="showSamples = true"
                            >fas fa-sort-down</v-icon
                          >
                          <v-icon
                            data-cy="sort-up"
                            class="float-right"
                            v-else
                            @click="showSamples = false"
                            >fas fa-sort-up</v-icon
                          >
                        </v-btn>
                      </v-row>
                    </td>
                  </tr>
                  <tr v-if="showSamples && building.is_asbestos">
                    <td>
                      <p class="body-2 ma-0 pa-0">Asbestos</p>
                    </td>
                    <td>
                      <p class="body-2 ma-0 pa-0 text-center" data-cy="count-sampled">
                        {{ building.asbestos_count }}
                      </p>
                    </td>
                  </tr>
                  <tr v-if="showSamples && building.is_lead">
                    <td>
                      <p class="body-2 ma-0 pa-0">Lead</p>
                    </td>
                    <td>
                      <p class="body-2 ma-0 pa-0 text-center" data-cy="count-sampled">
                        {{ building.lead_count }}
                      </p>
                    </td>
                  </tr>
                  <tr
                    v-if="
                      building.samples_count != 0 && showSamples && building.is_asbestos
                    "
                  >
                    <td>
                      <p class="body-2 ma-0 pa-0">Sampled</p>
                    </td>
                    <td>
                      <p class="body-2 ma-0 pa-0 text-center" data-cy="count-sampled">
                        {{ building.samples_count }}
                      </p>
                    </td>
                  </tr>
                  <tr
                    v-if="
                      building.sampled_as_count != 0 &&
                      showSamples &&
                      building.is_asbestos
                    "
                  >
                    <td>
                      <p class="body-2 ma-0 pa-0">Sampled As</p>
                    </td>
                    <td>
                      <p class="body-2 ma-0 pa-0 text-center" data-cy="count-sampled-as">
                        {{ building.sampled_as_count }}
                      </p>
                    </td>
                  </tr>
                  <tr
                    v-if="
                      building.previously_reported_count != 0 &&
                      showSamples &&
                      building.is_asbestos
                    "
                  >
                    <td>
                      <p class="body-2 ma-0 pa-0">Previously Reported</p>
                    </td>
                    <td>
                      <p class="body-2 ma-0 pa-0 text-center" data-cy="count-prev-report">
                        {{ building.previously_reported_count }}
                      </p>
                    </td>
                  </tr>
                  <tr
                    v-if="
                      building.presumed_count != 0 && showSamples && building.is_asbestos
                    "
                  >
                    <td>
                      <p class="body-2 ma-0 pa-0">Presumed</p>
                    </td>
                    <td>
                      <p class="body-2 ma-0 pa-0 text-center" data-cy="count-presumed">
                        {{ building.presumed_count }}
                      </p>
                    </td>
                  </tr>
                  <tr
                    v-if="
                      building.inaccessible_count && showSamples && building.is_asbestos
                    "
                  >
                    <td>
                      <p class="body-2 ma-0 pa-0">Inaccessible</p>
                    </td>
                    <td>
                      <p
                        class="body-2 ma-0 pa-0 text-center"
                        data-cy="count-inaccessible"
                      >
                        {{ building.inaccessible_count }}
                      </p>
                    </td>
                  </tr>
                  <tr class="info white--text" v-if="showSamples && building.is_asbestos">
                    <td>
                      <p class="body-2 ma-0 pa-0">Positive</p>
                    </td>
                    <td>
                      <p class="body-2 ma-0 pa-0 text-center" data-cy="count-positive">
                        {{ building.positive_count }}
                      </p>
                    </td>
                  </tr>
                  <tr class="info white--text" v-if="showSamples && building.is_asbestos">
                    <td>
                      <p class="body-2 ma-0 pa-0">Negative</p>
                    </td>
                    <td>
                      <p class="body-2 ma-0 pa-0 text-center" data-cy="count-negative">
                        {{ building.negative_count }}
                      </p>
                    </td>
                  </tr>
                  <tr class="info white--text" v-if="showSamples && building.is_asbestos">
                    <td>
                      <p class="body-2 ma-0 pa-0">Awaiting Result</p>
                    </td>
                    <td>
                      <p
                        class="body-2 ma-0 pa-0 text-center"
                        data-cy="count-awaiting-result"
                      >
                        {{ building.awaiting_result_count }}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row v-if="!isClearanceCert">
            <v-col class="py-0 my-0">
              <v-menu
                v-if="!notBuilding"
                ref="date-menu"
                v-model="dateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                class="menu-max"
                content-class="menu-max"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    spellcheck="true"
                    v-model="dateFormatted"
                    hint="DD/MM/YYYY format"
                    label="Survey Date"
                    placeholder="Select date survey was carried out"
                    persistent-hint
                    prepend-icon="fas fa-calendar-alt"
                    @blur="date = parseDate(dateFormatted)"
                    v-on="on"
                    class="pt-7 pb-4 px-2 menu-max survey-date-step"
                  >
                    <template v-slot:prepend v-if="!dateSet">
                      <v-icon color="error" class="mr-1"
                        >fas fa-exclamation-circle</v-icon
                      >
                      <v-icon>fas fa-calendar-alt</v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  @input="menu1 = false"
                  scrollable
                  full-width
                  :disabled="is_reviewed(building)"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
        <v-col md="6" cols="12">
          <a-img
            class="bldg-img-step"
            @click="$refs.file.click()"
            data-cy="image-display"
            style="max-height: 500px"
            v-if="buildingImg"
            :src="buildingImg"
          ></a-img>
          <br />
          <div class="text-center">
            <input
              ref="file"
              style="display: none"
              type="file"
              accept="image/*"
              @change="fileChange($event)"
            />
          </div>
        </v-col>
      </v-row>
      <v-row v-if="!isClearanceCert">
        <v-col md="6" cols="12" class="note-height mb-10">
          <building-notes
            v-if="!notBuilding"
            :notes="building.notes"
            :edits="building.status_id == 7"
            :key="building.updated_at"
            @updated="$emit('updated')"
          ></building-notes>
        </v-col>
        <v-col md="6" cols="12">
          <area-surveyed-edit
            v-if="!notBuilding"
            :asbestosArea="building.area_surveyed"
            :leadArea="building.lead_area_surveyed"
            @updated="$emit('updated')"
          ></area-surveyed-edit>
        </v-col>
      </v-row>

      <v-row v-if="!isClearanceCert">
        <v-col cols="12" class="note-height mb-10">
          <report-template
            v-if="!notBuilding"
            :building="building"
            :key="building.updated_at"
            @updated="$emit('updated')"
          ></report-template>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import BuildingNotes from "./BuildingNotes.vue";
import BuildingItems from "./BuildingItems.vue";
import BuildingHeading from "./BuildingHeading.vue";
import BuildingButtons from "./BuildingButtons.vue";
import AreaSurveyedEdit from "./AreaSurveyedEdit.vue";
import ReviewButtons from "./ReviewButtons.vue";
import ReportTemplate from "./report/ReportTemplate.vue";

export default {
  components: {
    BuildingNotes,
    BuildingButtons,
    BuildingItems,
    AreaSurveyedEdit,
    ReviewButtons,
    BuildingHeading,
    ReportTemplate,
  },
  props: {
    aBuilding: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters(["buildingStatuses", "survey", "is_reviewed", "is_reviewer", "isRoot"]),
    building: {
      get() {
        return this.aBuilding;
      },
      set(updated) {
        this.$emit("update:aBuilding", updated);
      },
    },
    notBuilding() {
      return !this.$route.matched.some(({ path }) => path.includes("building"));
    },
    channelId() {
      return this.building.id || this.$route.params.building;
    },
    isClearanceCert() {
      return (
        this.building.report_type && this.building.report_type == "Clearance Certificate"
      );
    },
    isAwaitingReview() {
      return this.building.status_name == "Awaiting Review" && !this.isClearanceCert;
    },
  },
  mounted() {
    this.dateFormatted = this.formatDate(this.building.survey_date);
    if (this.building.survey_date) this.dateSet = true;
    this.getApprovals(this.building.id);
    this.loadBuilding();
  },
  data: (vm) => ({
    dateSet: false,
    dialog: false,
    date: new Date().toISOString().substr(0, 10),
    dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    dateMenu: null,
    status: null,
    showSamples: false,
    headers: [
      {
        text: "Job Number",
        align: "left",
        sortable: false,
        value: "job_number",
      },
      { text: "Name", value: "name" },
      { text: "Client", value: "client_id" },
      { text: "Surveyor", value: "surveyor_id" },
      { text: "Reviewer", value: "reviewer_id" },
      { text: "Status", value: "status_id" },
    ],
    fileUp: null,
    buildingImg: null,
  }),
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
      if (this.date) {
        this.setSurveyDate({ id: this.building.id, date: this.date });
        this.dateSet = true;
      }
    },
    building(updated) {
      this.getFloors(updated.id);
      this.dateFormatted = this.formatDate(this.building.survey_date);
    },
    "building.updated_at"() {
      this.loadBuilding();
    },
  },
  methods: {
    ...mapActions([
      "getApprovals",
      "getBuilding",
      "getFloors",
      "setSurveyDate",
      "getReport",
      "getSurvey",
      "setLoading",
      "uploadBuildingImage",
    ]),
    loadBuilding() {
      this.setBuildingImg();
      this.$emit("updated");
    },
    setBuildingImg() {
      this.buildingImg = `/api/image/building/${
        this.building.id
      }?updated=${Math.random()}`;
    },
    goto(building) {
      this.$router.push(`/survey/${this.$route.params.survey}/building/${building.id}`);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    fileChange(event) {
      const reader = new FileReader();
      reader.onload = (event) => {
        this.resizeImage(event.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    },
    resizeImage(file) {
      const maxSize = 1800;
      const image = new Image();
      image.onload = () => {
        if (image.width < maxSize && image.height < maxSize) {
          this.uploadedImage = file;
          this.building.image = file;
        }
        const canvas = document.createElement("canvas");
        let width = image.width;
        let height = image.height;
        if (width > height) {
          if (width > maxSize) {
            height *= maxSize / width;
            width = maxSize;
          }
        } else {
          if (height > maxSize) {
            width *= maxSize / height;
            height = maxSize;
          }
        }
        canvas.width = width;
        canvas.height = height;
        canvas.getContext("2d").drawImage(image, 0, 0, width, height);
        canvas.toBlob((blob) => {
          this.building.image = new File(
            [blob],
            `${this.building.name}.png` || "building-image.png"
          );
        });
        const resizedImage = canvas.toDataURL("image/png", 1);
        this.uploadedImage = resizedImage;
        this.uploadBuildingImage({
          image: resizedImage,
          building_id: this.building.id,
        }).then(() => {
          this.setBuildingImg();
        });
      };
      image.src = file;
    },
  },
};
</script>

<style scoped>
.menu-max {
  max-width: 25em !important;
}

.table-max {
  height: 15em;
  overflow: auto;
}
</style>
