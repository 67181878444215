<template>
  <v-dialog v-model="dialog" fullscreen scrollable>
    <tour tourKey="polygon" />
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <v-skeleton-loader class="mx-auto" height="50em" width="60em" type="image" :loading="!imageData">
          <span></span>
        </v-skeleton-loader>
        <polygon-display class="polygon-step" ref="displayCanvas" v-if="imageData" :image="imageData" :updated="updated"
          :polygons="polygons"></polygon-display>
      </span>
    </template>
    <v-card v-show="dialog">
      <v-card-text>
        <v-row>
          <v-col class="pa-0 ma-0" data-cy="polygon-page">
            <v-btn data-cy="delete-polygon" color="primary" icon class="float-right mb-n1" @click.stop="close">
              <v-icon color="error" class="position-sticky">fas fa-times</v-icon>
            </v-btn>

          </v-col>
        </v-row>

        <v-row>
          <v-col class="text-center canvas-parent ma-0 px-0 pb-16">
            <polygon-canvas ref="polygonCanvas" v-bind:drawOptions.sync="options" v-bind:itemPolygons.sync="polygons"
              v-bind:itemLabels.sync="labels" :name="aItem.name" :single="single" :is_room="is_room"
              @canvasUpdate="updateDisplay" :imageData="image" v-bind:tool.sync="tool"></polygon-canvas>
          </v-col>


        </v-row>
        <v-row top class="hide-on-mobile">
          <v-col class="text-center">
            <p>* Press Alt key and click to pan the floor plan when zooming in and out</p>
          </v-col>
        </v-row>
        <v-row class="mx-2 pt-3 mb-0 pb-0" data-cy="polygon-action">
          <v-col class="px-0 pb-2 ma-0 d-inline sticky-bottom justify-center">
           
                  <v-btn data-cy="draw-polygon" fab class="mx-1 drawingOption primary" v-on="on" color="primary" title="Draw Polygon"
                    id="polygon">
                    <v-icon>fas fa-draw-polygon</v-icon>
                  </v-btn>
             

         
                <v-btn data-cy="draw-line" fab class="mx-1 drawingOption primary" v-on="on" id="polyline" color="primary" title="Draw Line">
                  <v-icon>fas fa-minus</v-icon>
                </v-btn>
            

            <!-- <div>
              <polygon-text-dialog
                v-bind:tool="tool"
                v-bind:polygonOptions.sync="polygons"
                v-bind:labelOptions.sync="label"
                v-bind:canvasOptions.sync="options"
              ></polygon-text-dialog>
            </div> -->

        
                <v-btn data-cy="complete-polygon" fab class="mx-1 editOption disabled success" id="complete-drawing" title="Complete Drawing"
                  v-on="on" disabled="disabled">
                  <v-icon>fas fa-check</v-icon>
                </v-btn>
           


          
                <v-btn data-cy="undo-polygon" fab class="mx-1 editOption v-btn--disabled warning" id="undo-drawing" title="Undo Drawing"
                  v-on="on" disabled="disabled">
                  <v-icon>fas fa-undo</v-icon>
                </v-btn>
        

         
                <v-btn data-cy="delete-polygon" fab class="mx-1 editOption v-btn--disabled error" v-on="on" title="Delete Drawing"
                  id="delete-drawing" disabled="disabled">
                  <v-icon>fas fa-trash</v-icon>
                </v-btn>
            
          <!-- </v-col>
          <v-col class="px-0 pb-2 ma-0 d-inline-flex justify-center"> -->

          
                <v-btn data-cy="zoom-in" fab class="mx-1 zoom-in primary" v-on="on" id="zoom-in" title="Zoom In">
                  <v-icon>fas fa-magnifying-glass-plus</v-icon>
                </v-btn>
          

     
                <v-btn data-cy="zoom-out" fab class="mx-1 primary" v-on="on" id="zoom-out" title="Zoom Out">
                  <v-icon>fas fa-magnifying-glass-minus</v-icon>
                </v-btn>
         
                <v-btn data-cy="pan-zoom" fab class="mx-1 greyed" v-on="on" id="pan-zoom" title="Pan Image">
                  <v-icon>fas fa-arrows-up-down-left-right</v-icon>
                </v-btn>
          
        
                <v-btn data-cy="reset-zoom" fab class="mx-1 primary" v-on="on" id="reset-zoom" title="Reset View">
                  <v-icon>fas fa-expand</v-icon>
                </v-btn>
           

        
                <v-btn fab v-on="on" @click="close" class="mx-1 success" title="Save & Close">
                  <v-icon data-cy="finish-polygon">fas fa-clipboard-check</v-icon>
                </v-btn>
            
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import PolygonCanvas from "./PolygonDrawingCanvas";
import PolygonDisplay from "./PolygonDisplay";
import Tour from "./Tour";
import { POLYGON, LABEL, LINE } from "../constants/drawing";
import PolygonTextDialog from "./PolygonTextDialog";

export default {
  components: {
    PolygonDisplay,
    PolygonCanvas,
    Tour,
    PolygonTextDialog,
  },
  props: {
    url: {
      type: String,
      default: null,
      required: false,
    },
    setColor: {
      type: Object,
      default: () => ({
        fill: { r: 255, g: 99, b: 71, a: 0.2 },
        stroke: { r: 255, g: 99, b: 71, a: 0.6 },
      }),
      required: false,
    },
    aItem: {
      type: Object,
      default: null,
      required: true,
    },
    selectedTool: {
      type: Number,
      default: null,
      required: false,
    },
    tools: {
      type: Array,
      default: () => [1, 2, 3],
      required: false,
    },
    single: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_room: {
      type: Boolean,
      default: false,
      required: false,
    },
    color: {
      type: Boolean,
      default: true,
      required: false,
    },
    buildingSize: {
      type: Number,
      default: 10,
      required: false,
    },
    allow_label: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  created() {
    this.POLYGON = POLYGON;
    this.LABEL = LABEL;
    this.LINE = LINE;
  },
  watch: {
    setColor(updated) {
      this.setToolColors(updated);
      ++this.updated;
    },
    image(updated) {
      this.setImageData(updated);
    },
    dialog(updated) {
      if (!updated && this.$refs.polygonCanvas) {
        this.$refs.polygonCanvas.completePolygon();
        this.$nextTick(() => {
          this.$refs.polygonCanvas.updateView();
        });
      }
    },
  },
  mounted() {
    this.setToolColors();
    this.addLabel = this.allow_label;
    this.reader = new FileReader();
    this.label.size = this.buildingSize;
    if (this.url) {
      this.getImageData(this.url).then((res) => {
        this.image = res;
      });
    }
    if (!this.url && this.image) {
      this.setImageData(this.image);
    }
    this.tool = POLYGON;
    if (this.selectedTool == LABEL) {
      this.tool = LABEL;
      this.options.fill = { r: 0, g: 88, b: 161, a: 1 };
      this.options.stroke = { r: 0, g: 88, b: 161, a: 1 };
    }
  },
  data: () => ({
    addLabel: false,
    reader: null,
    loading: true,
    image: null,
    imageData: null,
    canvas: null,
    fab: false,
    value: null,
    dialog: false,
    drawer: false,
    tool: null,
    updated: 0,
    options: {
      fill: { r: 255, g: 99, b: 71, a: 0.2 },
      stroke: { r: 255, g: 99, b: 71, a: 0.6 },
      lineDash: [],
      lineWidth: 2,
      cursorArea: 12,
    },
    polygon: {
      points: [],
      fill: { r: 255, g: 99, b: 71, a: 0.2 },
      stroke: { r: 255, g: 99, b: 71, a: 0.6 },
      lineWidth: 2,
      name: null,
      index: null,
      type: "polygon",
    },
    label: {
      point: [],
      fill: { r: 0, g: 0, b: 0, a: 1 },
      stroke: { r: 255, g: 255, b: 255, a: 1 },
      size: 24,
      lineWidth: 1,
      font: "serif",
      text: "#",
      index: null,
    },
  }),
  computed: {
    polygons: {
      get() {
        return this.aItem.layer.polygons;
      },
      set(updated) {
        this.$emit("update:aItem", {
          ...this.aItem,
          layer: { polygons: updated, labels: this.aItem.layer.labels },
        });
      },
    },
    labels: {
      get() {
        return this.aItem.layer.labels;
      },
      set(updated) {
        this.$emit("update:aItem", {
          ...this.aItem,
          layer: { polygons: this.aItem.layer.polygons, labels: updated },
        });
      },
    },
    lineWidth: {
      get() {
        return this.options.lineWidth || 4;
      },
      set(updated) {
        if (updated < 1 || updated > 40) return;
        this.options.lineWidth = updated;
      },
    },
    fill: {
      get() {
        return this.options.fill;
      },
      set(updated) {
        if (updated.a < 0.01) return;
        this.options.fill = updated;
      },
    },
    stroke: {
      get() {
        return this.options.stroke;
      },
      set(updated) {
        if (updated.a < 0.01) return;
        this.options.stroke = updated;
      },
    },
  },
  methods: {
    ...mapActions(["getImageData"]),
    setToolColors(updated = this.setColor) {
      if (updated) {
        if (!!updated?.fill?.a) {
          this.fill = { ...updated.fill };
          for (let aPolygon of this.polygons)
            aPolygon.fill = { ...updated.fill };
        }
        if (!!updated?.stroke?.a) {
          this.stroke = { ...updated.stroke };
          for (let aPolygon of this.polygons)
            aPolygon.stroke = { ...updated.stroke };
        }
        this.updateView();
      }
    },
    updateDisplay() {
      this.updated++;
    },
    updateView() {
      if (this.$refs?.polygonCanvas) {
        this.$refs.polygonCanvas.updateView();
      }
    },
    setImageData(imageData) {
      this.imageData = imageData;
      this.updated++;
    },
    setPolygonTool() {
      if (this.tool == LABEL) {
        this.$refs.polygonCanvas.completeLabel();
      }
      this.$nextTick(() => {
        this.tool = POLYGON;
        this.fill = { r: 255, g: 99, b: 71, a: 0.2 };
        this.stroke = { r: 255, g: 99, b: 71, a: 0.6 };
      });
    },
    undo() {
      this.$refs.polygonCanvas.undo();
    },
    clear() {
      this.$refs.polygonCanvas.clear();
    },
    isNumeric(n) {
      return !Number.isNaN(parseFloat(n)) && Number.isFinite(n);
    },
    close() {
      this.dialog = !this.dialog;
    },
  },
};
</script>

<style scoped>
.canvas-parent {
  overflow-x: auto;
  overflow-y: auto;
}

.sticky-bottom {
  position: -webkit-sticky;
  position: fixed;
  overflow-y: auto;
  bottom: 0;
}

/* Hide the row on mobile and iPad screens */
@media (max-width: 820px) {
  .hide-on-mobile {
    display: none;
  }
}
</style>
